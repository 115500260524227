import request from 'utils/request';
const api = {
  DMP_DATA_UPLOAD_PAGE: '/api/admin/dmp/upload/result/page',
  DMP_DATA_UPLOAD_DETAILS: '/api/admin/dmp/upload/result/get',
  DMP_DATA_UPLOAD_DEL: '/api/admin/dmp/upload/result/delete',
  DMP_DEVICE_NAME_UPDATE: '/api/admin/dmp/upload/name/update',
};

// 上传记录 分页
export function fetchDmpDataUploadPage(query) {
  return request({
    url: api.DMP_DATA_UPLOAD_PAGE,
    method: 'get',
    params: query,
  });
}

// 上传记录 详情
export function fetchDmpDataUploadDetails(query) {
  return request({
    url: api.DMP_DATA_UPLOAD_DETAILS,
    method: 'get',
    params: query,
  });
}
// 上传记录 删除
export function fetchDmpDataUploadDel(query) {
  return request({
    url: api.DMP_DATA_UPLOAD_DEL,
    method: 'get',
    params: query,
  });
}

export function updateDeviceNameAsyncInfo(query) {
  return request({
    url: api.DMP_DEVICE_NAME_UPDATE,
    method: 'get',
    params: query,
  });
}
